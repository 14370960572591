body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
  font-family: "Neue Campton Test";
  src: url("fonts/rb-campton/NeueCamptonTest-Regular.otf");
}

@font-face {
  font-family: "Neue Campton Test";
  src: url("fonts/rb-campton/NeueCamptonTest-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Neue Campton Test";
  src: url("fonts/rb-campton/NeueCamptonTest-SemiBold.otf");
  font-weight: 700;
}

